import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
// import Vconsole from 'vconsole'

// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(preview)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
