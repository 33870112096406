import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '',
  routes: [
    {
        path: '/',
        name: 'Vtour',
        component: () => import('../views/Vtour')
    },{
        path: '/videoPlayer',
        name: 'videoPlayer',
        component: () => import('../components/VideoPlayer')
      }
  ]
})
